import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import resumePdf from "../../static/Resume.pdf"

const SecondPage = () => (
  <Layout>
    <Seo title="Information" />
    <h1>Hello!</h1>
    <p></p>
    <a href={resumePdf} title="Resume download">Get my resume here</a>
    <br />
    <Link to="/">Return home</Link>
  </Layout>
)

export default SecondPage
